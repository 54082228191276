/* Private members */
const webChatScriptElement = document.getElementById('advbotscript');
const normalMode = webChatScriptElement.getAttribute('data-normal-mode')
  ? webChatScriptElement.getAttribute('data-normal-mode') === 'true'
  : false;
const customerId = webChatScriptElement.getAttribute('data-customer-id') ?? '';

let userIdleTime = 0;
let userIdleTimeIntervalId = null;
let advbotGuestEmail = null;
let advStuApiBaseUrl = null;
let advStuWebBaseUrl = null;
let encryptedDomainKey = null;
let isDomainRegistered = false;

/* Setters */

/**
 *
 * @param {string} baseUrl Base URL for the Advisory Studio API
 */
function setApiBaseUrl(baseUrl) {
  advStuApiBaseUrl = baseUrl;
}

/**
 *
 * @param {string} baseUrl Base URL for the Advisory Studio Web UI
 */
function setWebBaseUrl(baseUrl) {
  advStuWebBaseUrl = baseUrl;
}

/**
 *
 * @param {int} time User idle time
 */
function setUserIdleTime(time) {
  userIdleTime = time;
}

/**
 *
 * @param {string} encryptedKey Encrypted value of the domain where chatbot is initiated from.
 */
function setEncryptedDomainKey(encryptedKey) {
  encryptedDomainKey = encryptedKey;
}

/**
 *
 * @param {boolean} isRegistered Set the registration state of the client domain
 */
function setIsDomainRegistered(isRegistered) {
  isDomainRegistered = isRegistered;
}

function isDesignerMode(callback) {
  const hostUrl = new URL(window.location.href);
  const designModeQueryValue = hostUrl.searchParams.get('designMode');
  const isDesignModeEnabledOnWebsite = designModeQueryValue === 'true';

  console.info(`isDesignModeEnabledOnWebsite: ${isDesignModeEnabledOnWebsite}`);

  const request = {
    url: `${advStuApiBaseUrl}/api/webchat/designer/${customerId}`,
    method: 'GET',
  };

  $.ajax(request).done((response) => {
    console.info(`response: `, response);

    const isFirstUse = response.IsFirstUse === 'true';
    if (isFirstUse) {
      setEncryptedDomainKey(response.Key);
      window.location.href = `${advStuWebBaseUrl}/WebSiteChat/NewRegistration?id=${encodeURIComponent(
        encryptedDomainKey
      )}&cid=${customerId}`;
    } else {
      const isDesignerModeEnabledOnBackend = response.IsDesignerMode === 'true';
      console.info(
        `isDesignerModeEnabledOnBackend: ${isDesignerModeEnabledOnBackend}`
      );

      isDesignModeEnabledOnWebsite === isDesignerModeEnabledOnBackend
        ? callback(true)
        : callback(false);
    }
  });
}

/**
 * @type {Object} Object containing the Chat UI Elements
 */
const ChatElements = {
  advbotChatGoesHere: null,
  advbotLeadForm: null,
  advbotLeadDetails: null,
  advbotChatOptionsModal: null,
  advbotLeadTitle: null,
  advbotChatMenuBtn: null,
  advbotMicrophoneBtn: null,
};

// Show the Advisory Studio chat UI
function showChat() {
  ChatElements.advbotChatGoesHere.style.display = 'block';
  ChatElements.advbotLeadForm.style.display = 'none';
  ChatElements.advbotLeadDetails.style.height = '600px';
  ChatElements.advbotLeadDetails.style.display = 'block';
  ChatElements.advbotChatOptionsModal.style.display = 'none';
  ChatElements.advbotChatMenuBtn.style.display = 'block';
  ChatElements.advbotMicrophoneBtn.style.display = 'block';
}

// Show the Advisory Studio leads UI
function showLead() {
  ChatElements.advbotLeadTitle.innerHTML =
    ChatElements.advbotLeadTitle.innerHTML || 'Sales Chat';
  ChatElements.advbotChatGoesHere.style.display = 'none';
  ChatElements.advbotLeadForm.style.display = 'block';
  ChatElements.advbotLeadDetails.style.height = '510px';
  ChatElements.advbotLeadDetails.style.display = 'block';
  ChatElements.advbotChatOptionsModal.style.display = 'none';
}

/**
 * Binds the theme color defined in the WebChat script element
 */
function bindTheme() {
  let themeColor = null;

  if (webChatScriptElement) {
    themeColor = webChatScriptElement.getAttribute('data-theme-color');
  }

  if (!themeColor) {
    return;
  }

  const cssClass = `<style> .advbot-modal-header, .advbot-btn-success, .advbot-btn-primary, .advbot-imgChatBotDesktop { background-color: #${themeColor}; border-color: #${themeColor} !important } </style>`;
  $('body').append(cssClass);
}

// Helper method to get the field within the form
function getField(fieldName) {
  return ChatElements.advbotLeadForm.elements.namedItem(fieldName);
}

// Helper method to read the UI field value
function getFieldValue(fieldName) {
  return getField(fieldName).value;
}

// Increments the user Idle time and executes an idle timeout if the user is away for too long
//function timerIncrement() {
//  userIdleTime += 1;

//  if (userIdleTime > 3 * 60) {
//    // 5 secs
//    clearInterval(userIdleTimeIntervalId);

//    const settings = {
//      url: `${advStuApiBaseUrl}/api/bot/manager/idletimeout`,
//      method: 'POST',
//      'Content-Type': 'application/json',
//        dataType: 'json',
//        data: { advbotGuestEmail, customerId },
//    };

//    $.ajax(settings).done(() => {});
//  }
//}

// Helper method to sanitize user attributes
//function filterUserAttributes(str) {
//  if (!str) {
//    return '';
//  }

//  return str.replace(/|/gi, '');
//}

// Initializes the chat bot.
function initiateChatBot(email, name, company, phone, callback) {
  //const settings = {
  //    url: `${advStuApiBaseUrl}/api/bot/manager/GenerateDirectLineToken/${customerId}`,
  //method: 'GET',
  //};
  (async function () {
    const url = `${advStuApiBaseUrl}/api/bot/manager/GenerateDirectLineToken/${customerId}`;
    const res = await fetch(url, { method: 'GET' });
    const { token, key } = await res.json();
    console.log(token);
    console.log(key);
    const isGuest = email != null;

    const avatarOptions = {
      botAvatarImage: document
        .getElementById('advbotBtn')
        .getAttribute('data-is-icon')
        ? `https://ascoredevscusstorage.blob.core.windows.net/public/images/bot-${customerId}.png`
        : 'https://ascoredevscusstorage.blob.core.windows.net/public/images/bot-icon.png',
      botAvatarInitials: 'Bt',
      userAvatarInitials: 'US',
      userAvatarImage: document
        .getElementById('advbotBtn')
        .getAttribute('data-is-icon')
        ? `https://ascoredevscusstorage.blob.core.windows.net/public/images/botuser-${customerId}.png`
        : 'https://ascoredevscusstorage.blob.core.windows.net/public/images/bot-icon.png',
    };
    const selectedProject = callback ? callback() : null;
    console.log('Selected Project: ', selectedProject);

    const store = window.WebChat.createStore(
      {},
      ({ dispatch }) =>
        (next) =>
        (action) => {
          if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
            console.log('Inside DIRECT_LINE/CONNECT_FULFILLED');
            dispatch({
              type: 'WEB_CHAT/SEND_EVENT',
              payload: {
                name: 'webchat/join',
                value: {
                  guestData: {
                    isGuest,
                    email,
                    name,
                    company,
                    phone,
                    key: key,
                    customerId: customerId,
                  },
                  projectData: {
                    projectName: selectedProject
                      ? selectedProject.selectedProject
                      : null,
                    projectGUID: selectedProject
                      ? selectedProject.selectedTemplateGUID
                      : null,
                    isWebchat: selectedProject ? selectedProject.webChat : true,
                    authToken: localStorage.getItem('ls.authorizationData')
                      ? localStorage
                          .getItem('ls.authorizationData')
                          .replace(/^"(.*)"$/, '$1')
                      : '',
                  },
                },
              },
            });
          }
          return next(action);
        }
    );

    window.WebChat.renderWebChat(
      {
        directLine: window.WebChat.createDirectLine({ token }),
        store: store,
        userID: 'User',
        host: window.location.hostname,
        isGuest: email != null,
        locale: 'en-US',
        styleOptions: avatarOptions,
      },
      ChatElements.advbotChatGoesHere
    );
    showChat();
    $('.webchat__send-box-text-box__input')[0].placeholder =
      "Type message or type 'help' to list commands";
    if (isGuest || !normalMode) {
      //userIdleTimeIntervalId = setInterval(timerIncrement, 1 * 1000);
      advbotGuestEmail = email;
    }
  })();
  //  $.ajax(settings).done((response) => {
  //    // Create the styling options for the webchat.
  //    const avatarOptions = {
  //      botAvatarImage: document.getElementById('advbotBtn').getAttribute("data-is-icon")?`https://ascoredevscusstorage.blob.core.windows.net/public/images/bot-${customerId}.png`:'https://ascoredevscusstorage.blob.core.windows.net/public/images/bot-icon.png',
  //      botAvatarInitials: 'Bt',
  //      userAvatarInitials: 'US',
  //      userAvatarImage: document.getElementById('advbotBtn').getAttribute("data-is-icon") ? `https://ascoredevscusstorage.blob.core.windows.net/public/images/botuser-${customerId}.png` : 'https://ascoredevscusstorage.blob.core.windows.net/public/images/bot-icon.png',
  //    };

  //    // Enable DirectLine Speech by creating the adaptors.
  //      //const directline = window.WebChat.createDirectLine({
  //      //    token: response.token
  //      //});

  //      const selectedProject = callback ? callback() : null;
  //      console.log('Selected Project: ', selectedProject);
  //      console.log('authToken', "");

  //      const store = window.WebChat.createStore({}, ({ dispatch }) => next => action => {
  //          if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
  //              console.log('Inside DIRECT_LINE/CONNECT_FULFILLED')
  //              dispatch({
  //                  type: 'WEB_CHAT/SEND_EVENT',
  //                  payload: {
  //                      name: 'webchat/join',
  //                      value: {
  //                          guestData: {
  //                              isGuest,
  //                              email,
  //                              name,
  //                              company,
  //                              phone,
  //                              key: response.key,
  //                          },
  //                          projectData: {
  //                              projectName: selectedProject ? selectedProject.selectedProject : null,
  //                              projectGUID: selectedProject ? selectedProject.selectedTemplateGUID : null,
  //                              isWebchat: selectedProject ? selectedProject.webChat : true,
  //                              authToken: localStorage.getItem('ls.authorizationData') ? localStorage.getItem('ls.authorizationData').replace(/^"(.*)"$/, '$1') : '',
  //                          }
  //                      }
  //                  }
  //              });
  //          }
  //          return next(action);
  //      });
  //      // Render webchat with DirectLine speech adaptors
  //      window.WebChat.renderWebChat(
  //        {
  //              directline: window.WebChat.createDirectLine({
  //                  token: response.token
  //              }),
  //              store: store,
  //              userID: "dl_"+name,
  //              host: window.location.hostname,
  //              isGuest: email != null,
  //              locale: 'en-US',
  //              styleOptions: avatarOptions,
  //        },
  //        ChatElements.advbotChatGoesHere
  //      );

  //        showChat();
  //        $('.webchat__send-box-text-box__input')[0].placeholder = 'Type message or type \'help\' to list commands';
  //        //setupTokenRefresh(sourceDirectLine);

  //      //if (userIdleTimeIntervalId) {
  //      //  clearInterval(userIdleTimeIntervalId);
  //      //}

  //      if (isGuest || !normalMode) {
  //        //userIdleTimeIntervalId = setInterval(timerIncrement, 1 * 1000);
  //        advbotGuestEmail = email;
  //      }
  //    });
  //}

  //function setupTokenRefresh(directLine) {
  //    const settings = {
  //        url: `${advStuApiBaseUrl}/api/bot/manager/GetDirectLineSpeechRefreshToken`,
  //        method: 'GET',
  //    };

  //    setTimeout(() => {
  //        $.ajax(settings).done(response => {
  //            if (response) {
  //                directLine.updateCredentials({ token: response.speechToken });
  //                setupTokenRefresh();
  //            }
  //        });
  //    }, 5 * 60 * 1000);
  //}
}
// Binds the lead generation form validation
function bindLeadFormValidation() {
  const forms = document.getElementsByClassName('advbot-needs-validation');

  Array.prototype.filter.call(forms, (form) => {
    form.addEventListener(
      'submit',
      (event) => {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        } else {
          event.preventDefault();
          event.stopPropagation();

          const email = getFieldValue('email');
          const name = getFieldValue('name');
          const company = getFieldValue('company');
          const phone = getFieldValue('phone');
          initiateChatBot(email, name, company, phone);
        }
        form.classList.add('was-validated');
      },
      false
    );
  });
}

/**
 * Binds the interaction logic for the web chat
 */
function bindInteractions(callback) {
  ChatElements.advbotChatGoesHere =
    document.getElementById('advbotChatGoesHere');
  ChatElements.advbotLeadForm = document.getElementById('advbotLeadForm');
  ChatElements.advbotLeadDetails = document.getElementById('advbotLeadDetails');
  ChatElements.advbotChatOptionsModal = document.getElementById(
    'advbotChatOptionsModal'
  );
  ChatElements.advbotLeadTitle = document.getElementById('advbotLeadTitle');
  //Button for mic
  ChatElements.advbotChatMenuBtn = document.getElementById('btnChatMenu');
  ChatElements.advbotMicrophoneBtn = document.getElementById('btnMicrophone');

  // Get the button that opens the modal
  // const btnSalesChat = advbotBtnSalesChat;
  // const btnSupportChat = advbotBtnSupportChat;
  const btnSalesChat = document.getElementById('advbotBtnSalesChat');
  const btnSupportChat = document.getElementById('advbotBtnSupportChat');

  // Get the <span> element that closes the modal
  const spanClose = document.getElementsByClassName('advbot-close-form')[0];

  // Get the modal
  // const modal = advbotChatOptionsModal;

  // Get the button that opens the modal
  const btn = document.getElementById('advbotBtn');

  // Want to show popup
  const isOpenPopup = btn.getAttribute('data-opening-popup');

  // Bot avatar image
  //const isBotImage = btn.getAttribute("data-is-icon");

  // Get the <span> element that closes the modal
  const span = document.getElementsByClassName('advbot-close')[0];

  btn.onclick = () => {
    if (normalMode) {
      ChatElements.advbotLeadTitle.innerHTML = 'Advisory Studio';
      initiateChatBot('', '', '', '', callback);
    } else if (isDomainRegistered) {
      if (isOpenPopup == 'true')
        ChatElements.advbotChatOptionsModal.style.display = 'block';
      else {
        showLead();
      }
      bindLeadFormValidation();
    } else {
      const win = window.open(
        `${advStuWebBaseUrl}/WebSiteChat/NewRegistration?id=${encodeURIComponent(
          encryptedDomainKey
        )}`,
        '_blank'
      );
      win.focus();
    }
  };

  // When the user clicks on <span> (x), close the modal
  span.onclick = () => {
    ChatElements.advbotChatOptionsModal.style.display = 'none';
  };

  // When the user clicks anywhere outside of the modal, close it
  window.onclick = (event) => {
    if (event.target === ChatElements.advbotChatOptionsModal) {
      ChatElements.advbotChatOptionsModal.style.display = 'none';
    }
  };

  // When the user clicks the button, open the modal
  btnSalesChat.onclick = showLead;
  btnSupportChat.onclick = () => {
    ChatElements.advbotLeadTitle.innerHTML = 'Support Chat';
    initiateChatBot();
  };

  spanClose.onclick = () => {
    ChatElements.advbotLeadDetails.style.display = 'none';
  };
}

function isValidEmail(email) {
  // eslint-disable-next-line no-useless-escape
  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  return emailRegex.test(email);
}

function isValidPhoneNumber(phoneNumber) {
  // eslint-disable-next-line no-useless-escape
  const phoneRegex =
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  return phoneRegex.test(phoneNumber);
}

function showErrorMessage(elementId) {
  document.getElementById(elementId).style.display = 'block';
}

function hideErrorMessage(elementId) {
  document.getElementById(elementId).style.display = 'none';
}

function showErrorHighlight(element) {
  element.classList.add('form-invalid');
}

function hideErrorHighlight(element) {
  element.classList.remove('form-invalid');
}

function enableSubmit() {
  document.getElementById('submit').disabled = false;
}

function disableSubmit() {
  document.getElementById('submit').disabled = true;
}

function validateEmail() {
  const emailField = getField('email');

  if (isValidEmail(emailField.value)) {
    hideErrorHighlight(emailField);
    hideErrorMessage('emailValidationMessage');
    enableSubmit();
  } else {
    showErrorHighlight(emailField);
    showErrorMessage('emailValidationMessage');
    disableSubmit();
  }
}

function validatePhoneNumber() {
  const phoneField = getField('phone');

  if (isValidPhoneNumber(phoneField.value)) {
    hideErrorHighlight(phoneField);
    hideErrorMessage('phoneValidationMessage');
    enableSubmit();
  } else {
    showErrorHighlight(phoneField);
    showErrorMessage('phoneValidationMessage');
    disableSubmit();
  }
}
function validateName() {
  const nameField = getField('name');

  if (nameField.value == '' || nameField.value == undefined) {
    showErrorHighlight(nameField);
    showErrorMessage('nameValidationMessage');
    disableSubmit();
  } else {
    hideErrorHighlight(nameField);
    hideErrorMessage('nameValidationMessage');
    enableSubmit();
  }
}

function bindValidations() {
  getField('email').addEventListener('blur', validateEmail);
  getField('phone').addEventListener('blur', validatePhoneNumber);
  getField('name').addEventListener('blur', validateName);
}

export default {
  ChatElements,
  setApiBaseUrl,
  setWebBaseUrl,
  setUserIdleTime,
  setEncryptedDomainKey,
  setIsDomainRegistered,
  bindTheme,
  bindInteractions,
  bindValidations,
  isDesignerMode,
};
