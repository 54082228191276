import UI from './shared/webchat-interaction';

$(document).ready(() => {
  const advStuApiUrl = 'https://apidev.advisorystudio.io';
  const advStuWebUrl = 'https://webdev.advisorystudio.io';

  UI.setApiBaseUrl(advStuApiUrl);
  UI.setWebBaseUrl(advStuWebUrl);

  UI.isDesignerMode((showWebchat) => {
    // Only render the webchat if showWebchat is true

    console.info(`showWebChat: ${showWebchat}`);

    if (showWebchat) {
      const settings = {
        url: `${advStuApiUrl}/api/bot/manager/script`,
        method: 'GET',
      };

      $.ajax(settings).done((response) => {
        UI.setIsDomainRegistered(response.isRegistered);
        UI.setEncryptedDomainKey(response.url);

        $('body').append(response.content);

        UI.bindTheme();
        UI.bindInteractions();
        UI.bindValidations();
      });

      $(document).mousemove(() => {
        UI.setUserIdleTime(0);
      });

      $(document).keypress(() => {
        UI.setUserIdleTime(0);
      });
    } else {
      console.log('%cAdvisory Studio WebChat is currently disabled.', 'background: #cff4fc; color: #0a58ca');
    }
  });
});
